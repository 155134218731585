import React, { useState } from "react";
import { ButtonComponent } from "../components/ButtonComponent";

interface Props {
  validationMessage: string | undefined;
  submitCallback: (credentials: { username: string }) => void;
}
const UsernameForm: React.FC<Props> = ({
  validationMessage,
  submitCallback,
}) => {
  const [username, setUsername] = useState("");

  const onSubmit = () => {
    submitCallback({ username });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
        marginTop: 10,
      }}
    >
      <span style={{ fontSize: 14 }}>Enter the email you wish to reset</span>
      <div style={{ width: "400px", marginTop: 40 }}>
        <div>
          <label style={{ fontSize: 18 }}>Email address</label>
          <input
            type="email"
            className={`signin-input ${
              !!validationMessage ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        {validationMessage ? (
          <div className="text-danger mb-2">{validationMessage}</div>
        ) : (
          ""
        )}
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <ButtonComponent
            name="Submit"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "400px",
              height: "50px",
              border: "2px solid #FFFFFF",
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "18px",
            }}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default UsernameForm;
