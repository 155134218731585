import React, { useState } from "react";
import { ButtonComponent } from "../components/ButtonComponent";

interface Props {
  validationMessage: string | undefined;
  submitCallback: (credentials: { newPassword: string; code: string }) => void;
}
const ChangePassForm: React.FC<Props> = ({
  validationMessage,
  submitCallback,
}) => {
  const [code, setCode] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const onSubmit = () => {
    submitCallback({ newPassword: newPassword1, code });
  };

  const passwordMatch =
    newPassword1.length > 0 &&
    newPassword2.length > 0 &&
    newPassword1 !== newPassword2
      ? "Passwords do not match"
      : undefined;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#ffffff",
      }}
    >
      <div style={{ width: "400px" }}>
        <div style={{ fontSize: "14px" }}>
          Check your email for a reset code.
          <br />
          Enter the code from your email and a new password.
        </div>
        <div style={{ marginTop: 30, fontSize: "18px" }}>
          <label>Reset Code</label>
          <input
            className={`signin-input ${
              !!validationMessage ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <label>New Password</label>
          <input
            type="password"
            className={`signin-input ${
              !!validationMessage || passwordMatch ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={newPassword1}
            onChange={(e) => setNewPassword1(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <label>Confirm New Password</label>
          <input
            type="password"
            className={`signin-input ${
              !!(validationMessage || passwordMatch) ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </div>
        {validationMessage || passwordMatch ? (
          <div className="text-danger mb-2">
            {validationMessage || passwordMatch}
          </div>
        ) : (
          ""
        )}
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <ButtonComponent
            name="Submit"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "400px",
              height: "50px",
              border: "2px solid #FFFFFF",
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "18px",
            }}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassForm;
