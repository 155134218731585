import React from "react";
import rightpanel from "../images/background/Alchemy-Analytics-Desktop.jpg";
import leftpanel from "../images/background/AlchemyBG-Login.jpg";
import LoginLogo from "../images/svg/logos/LoginLogo";
interface Props {
  component: JSX.Element;
  header: string;
}

export const LogonFormWrapper: React.FC<Props> = ({ component, header }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        backgroundColor: "#FAFAFA",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          aspectRatio: "1440/800",
          height: "100%",
          maxHeight: 800,
          // maxWidth: 1440,
          display: "flex",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "50%",
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundImage: `url(${leftpanel})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",
            backgroundPosition: "center center",
          }}
        >
          <div style={{ marginTop: 146 }}>
            <LoginLogo fill="#fff" />
          </div>
          <span
            style={{
              fontSize: 32,
              fontWeight: 400,
              color: "#ffffff",
              marginTop: 49,
            }}
          >
            {header}
          </span>

          {component}
        </div>
        <div
          style={{
            width: "50%",
            height: "100%",
            backgroundImage: `url(${rightpanel})`,
            backgroundSize: "cover",
            backgroundRepeat: "none",
            backgroundPosition: "center center",
          }}
        ></div>
      </div>
    </div>
  );
};
