import React, { useState } from "react";

interface Props {
  validationMessage: string | undefined;
  submitCallback: (credentials: { newPassword: string }) => void;
}
const ChangePassForm: React.FC<Props> = ({
  validationMessage,
  submitCallback,
}) => {
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const onSubmit = () => {
    submitCallback({ newPassword: newPassword1 });
  };

  const passwordMatch =
    newPassword1.length > 0 &&
    newPassword2.length > 0 &&
    newPassword1 !== newPassword2
      ? "Passwords do not match"
      : undefined;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "60%" }}>
        <div>Please Enter a New Password</div>
        <div style={{ marginTop: 30 }}>
          <label>New Password</label>
          <input
            type="password"
            className={`form-control ${
              !!validationMessage || passwordMatch ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={newPassword1}
            onChange={(e) => setNewPassword1(e.target.value)}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <label>Confirm New Password</label>
          <input
            type="password"
            className={`form-control ${
              !!(validationMessage || passwordMatch) ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </div>
        {validationMessage || passwordMatch ? (
          <div className="text-danger mb-2">
            {validationMessage || passwordMatch}
          </div>
        ) : (
          ""
        )}
        <div style={{ textAlign: "center" }}>
          <button
            type="submit"
            className="btn btn-primary"
            style={{
              borderRadius: 10,
              width: "80%",
              marginTop: 40,
              fontSize: 14,
            }}
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassForm;
