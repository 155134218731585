import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ButtonComponent } from "../components/ButtonComponent";

interface Credentials {
  username: string;
  password: string;
}

interface Props {
  validationMessage: string | undefined;
  submitCallback: (credentials: Credentials) => void;
}

export const SignInForm: React.FC<Props> = ({
  validationMessage,
  submitCallback,
}) => {
  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });

  const onSubmit = () => {
    submitCallback(credentials);
  };

  const emailChangeHandler = (value: string): void => {
    setCredentials({ ...credentials, username: value || "" });
  };

  const passwordChangeHandler = (value: string): void => {
    setCredentials({ ...credentials, password: value || "" });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
      }}
    >
      <div style={{ width: 400, marginLeft: 165 }}>
        <div>
          <label
            style={{
              color: "#ffffff",
              fontSize: 18,
              marginTop: 50,
              marginBottom: 0,
            }}
          >
            Email address
          </label>
          <input
            type="email"
            className="signin-input"
            value={credentials.username}
            onChange={(e) => emailChangeHandler(e.target.value)}
            style={{ height: 40, marginTop: 10, fontSize: 18 }}
          />
        </div>
        <div>
          <label
            style={{
              color: "#ffffff",
              fontSize: 18,
              marginTop: 21,
              marginBottom: 0,
            }}
          >
            Password
          </label>
          <input
            type="password"
            className={`signin-input ${
              !!validationMessage ? " is-invalid" : ""
            }`}
            maxLength={55}
            value={credentials.password}
            onChange={(e) => passwordChangeHandler(e.target.value)}
            style={{ height: 40, marginTop: 10 }}
          />
        </div>
        {validationMessage ? (
          <div className="text-danger mb-2">{validationMessage}</div>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: 12,
            marginTop: 20,
            color: "#ffffff",
          }}
        >
          <div style={{ display: "flex" }}>
            <input style={{ accentColor: "#ffffff" }} type="checkbox"></input>
            <span style={{ marginLeft: 10 }}>Remember me</span>
          </div>
          <Link to="/reset-password">
            <span className="reset-password">Reset Password</span>
          </Link>
        </div>
        <div style={{ textAlign: "center", marginTop: 40 }}>
          <ButtonComponent
            name="Submit"
            hoverStyle={{
              backgroundColor: "#757575",
              border: "2px solid #757575",
            }}
            style={{
              width: "400px",
              height: "50px",
              border: "2px solid #FFFFFF",
              marginLeft: "0px",
              marginRight: "0px",
              borderRadius: "2px",
              backgroundColor: "transparent",
              color: "#ffffff",
              fontSize: "18px",
            }}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </div>
  );
};
